:root {
  --success-color:#689F38;
  --info-color:#0288D1;
  --warning-color:#FBC02D;
  --danger-color:#D32F2F;
  --help-color:#9C27B0;
  --light-grey-color:#a6aaaf;
  --dark-grey-color:#575f6a;

  --animate-duration: 200ms;
  --animate-delay: 0.0s;
}

html {
  font-size: 13px;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--surface-a);
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

/* Panel body */
.panel-body {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
  padding: 1rem;
}

.hidden {
  display: none !important;
}

/* Text colors */
.text-primary { color: var(--cyan-800); }
.text-default { color: var(--surface-600); }
.text-success { color: var(--success-color); }
.text-info { color: var(--info-color); }
.text-warning { color: var(--warning-color); }
.text-danger { color: var(--danger-color); }
.text-help { color: var(--help-color); }
.text-secondary { color: var(--text-color-secondary); }
.text-light-gray { color: var(--light-grey-color); }
.text-dark-gray { color: var(--dark-grey-color); }

/* Text styles */
.text-underline { text-decoration: underline; }

/* Material icons default size */
.material-icons {
  font-size: 1rem;
}

