.text-view {
  all: unset;
  display: flex;
  flex-direction: row;
  width: 100%;
  > span {
    display: inline-flex;
    margin: 0 5px;
    flex: 0;
    align-self: center;
  }
  > span.text-view-text {
    flex: 1;
  }
}
