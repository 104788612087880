.p-progressbar-determinate .p-progressbar-value-animate {
  transition: width 100ms ease-in-out;
}

.p-cell-editing .p-fileupload .p-button-icon-left {
  margin-right: 0;
}

.p-cell-editing .p-fileupload {
  display: flex;
}

.p-fileupload .p-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}