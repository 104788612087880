.p-inputnumber {
  flex: 1 1 auto;
  width: 100%;
}

.p-inputnumber-input {
  width: 100%;
}

.p-inputnumber + .p-slider {
  border-radius: 0;
}

.with-slider > .p-inputtext {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.with-icon > .p-inputtext {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.with-unit > .p-inputtext {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
