.pdf-viewer {
  margin-top: 50px;
  .pdfobject-container  {
    max-width: 100%;
    max-height: 100%;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    iframe {
      min-width: 100%;
      min-height: 100%;
    }
  }
}

