.log-container {
  margin-bottom: -1.5rem;
  position: relative;
}

.log-button-autoload {
  outline: 0;
  position: absolute;
  top: 4px;
  left: 24px;
}

.log-loading-dots {
  position: absolute;
  bottom: 10px;
  left: 84px;
}

.animation-dot {
  font-size: 8px;
  color: #ccc;
}

.fade1 {
  animation: fade1 ease 1.5s infinite;
}
@keyframes fade1 {
  0% {
    opacity:0.4;
  }
  50% {
    opacity:0.8;
  }
  100% {
    opacity:0.4;
  }
}

.fade2 {
  animation: fade2 ease 1.5s infinite;
}
@keyframes fade2 {
  0% {
    opacity:0.6;
  }
  25% {
    opacity:0.4;
  }
  50% {
    opacity:0.6;
  }
  75% {
    opacity:0.8;
  }
  100% {
    opacity:0.6;
  }
}

.fade3 {
  animation: fade3 ease 1.5s infinite;
}
@keyframes fade3 {
  0% {
    opacity:0.8;
  }
  50% {
    opacity:0.4;
  }
  100% {
    opacity:0.8;
  }
}
