.p-treetable {
  &.p-treetable-sm .p-treetable-tbody > tr > td.p-cell-editing {
    padding: 1px;
  }

  .p-column-header-content {
    justify-content: center;
  }

  .p-treetable-tbody > tr .p-treetable-emptymessage {
    display: none;
  }

  .p-treetable-scrollable-wrapper, .p-treetable-scrollable-view {
    display: flex;
    flex: 1 1;
    flex-direction: column;
  }

  .p-treetable-scrollable-wrapper .p-treetable-scrollable-view .p-treetable-scrollable-body {
    overflow-y: scroll;
    flex: 1 1;
    position: relative;

    .p-treetable-scrollable-body-table {
      position: absolute;
    }
  }

  .p-treetable-expander-column {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
  }
}
