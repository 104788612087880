.info-dropdown {
  width: 200px;
}

.info-dropdown.p-overlaypanel:after {
  border: none;
}

.info-dropdown.p-overlaypanel:before {
  border: none;
}