.grid-container .p-paginator {
  border-top: 1px solid var(--surface-c);
}

.p-datatable,
.p-datatable .p-datatable-wrapper {
  flex-basis: 0;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td.p-cell-editing {
  padding: 1px;
}

.p-datatable .p-column-header-content {
  justify-content: center;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-emptymessage {
  display: none;
}

.p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead {
  table-layout: auto;
}
