.p-overlay-badge .p-avatar {
  margin: 0.2rem 0.5rem;
}

.avatar-component {
  display: inline-flex;
  cursor: pointer;
}

.avatar-component .avatar-name {
  line-height: 2.4rem;
}
