@import "expandible";

.p-panel.expandible-vertical > .p-toggleable-content {
  .expandible-vertical;
  .flex(1);
}

.p-panel.expandible-horizontal > .p-toggleable-content {
  .expandible-horizontal;
  .flex(1);
}

.p-panel.expandible-vertical > .p-toggleable-content > .p-panel-content {
  .expandible-vertical;
  .flex(1);
}

.p-panel.expandible-horizontal > .p-toggleable-content > .p-panel-content {
  .expandible-horizontal;
  .flex(1);
}