.help-viewer {
  max-width: 800px;
}

.p-dialog-content {
  display: flex;
  flex-direction: column;
}

hr.help-separator {
  border: 0;
  border-top: 1px solid var(--surface-300);
}

img.help-image {
  max-width: 100%;
}
